/****
 **
 ** Plugins
 **
 ****/

// object-fit-images
import objectFitImages from 'object-fit-images'

// jQuery
import '../vendor/magnific-popup.min.js'

class Common {
  constructor() {
    this.menu()
    this.imagefit()
    this.anchor()
    this.news()
    this.checkCheckbox()
    this.accordion()
    this.modalImage()
    //this.pagetopScroll();
  }
  /****
   **
   ** Menu
   **
   ****/
  menu() {
    const contents = $('.p-nav')
    const trigger = $('.p-nav__button')
    const overlay = $('.p-nav__overlay')

    trigger.on('click', function () {
      contents.toggleClass('is-open')
    })

    overlay.on('click', function () {
      contents.removeClass('is-open')
    })
  }

  /****
   **
   ** Image fit
   **
   ****/
  imagefit() {
    var fit_images = document.querySelectorAll('img.js-imagefit')
    objectFitImages(fit_images)
  }

  /****
   **
   ** Anchor
   **
   ****/
  anchor() {
    var self = this

    $('.js-anchor').on('click', function () {
      var speed = 400
      var href = $(this).attr('href') !== undefined ? $(this).attr('href') : $(this).attr('data-target')
      var target = $(href == '#' || href == '' ? 'html' : href)
      var position = target.offset().top - $('.l-header').outerHeight()
      var $checkbox = $('.js-check-checkbox-target')
      var arr = []
      $('body,html').animate({ scrollTop: position }, speed, 'swing')

      $('.p-nav').removeClass('is-open')

      if ($(this).attr('data-checkbox') !== undefined && $checkbox.length) {
        arr = $(this).attr('data-checkbox').split(',')
        self.onCheckbox(arr)
      }
      return false
    })

    $('.c-pagetop').on('click', function () {
      var speed = 400
      var position = 0
      $('body,html').animate({ scrollTop: position }, speed, 'swing')
      return false
    })
  }

  /****
   **
   ** NEWS Slider
   **
   ****/
  news() {
    const news__wrapper = $('.p-news__wrapper')
    const news_len = $('.p-news__item').length / 2

    if (news_len > 1) {
      const w = 100 * (news_len + 2) + '%'
      news__wrapper.css('width', w)

      const news_slide = new Swiper('.p-news', {
        wrapperClass: 'p-news__wrapper',
        slideClass: 'p-news__item',
        navigation: {
          nextEl: '.p-news__arrow--next',
          prevEl: '.p-news__arrow--prev',
        },
        loop: true,
      })
    } else {
      $('.p-news__arrow').css('opacity', '0')
    }
  }

  /****
   **
   ** check checkbox（チェックボックを自動でオン）
   **
   ****/
  checkCheckbox() {
    const $hidden = $('.js-check-checkbox')
    const arr = []

    if ($hidden.length) {
      $hidden.each((index, element) => {
        arr.push($(element).val())
      })

      this.onCheckbox(arr)
    }
  }

  /****
   **
   ** onCheckbox（チェックボックスにcheckedをつける）
   **
   ****/
  onCheckbox(arr) {
    const $target = $('.js-check-checkbox-target')

    if ($target.length) {
      arr.forEach((val, index) => {
        $target.filter(`[value="${val}"]`).prop('checked', true)
      })
    }
  }

  /****
   **
   ** accordion
   **
   ****/
  accordion() {
    const $target = $('.js-accordion')

    $target.each(function () {
      const $this = $(this)
      const $title = $this.find('.js-accordion-title')
      const $body = $this.find('.js-accordion-body')

      $title.on('click', () => {
        if ($this.hasClass('is-open')) {
          $this.removeClass('is-open')
          $title.removeClass('is-open')
          $body.slideUp()
        } else {
          $this.addClass('is-open')
          $title.addClass('is-open')
          $body.slideDown()
        }
      })
    })
  }

  /****
   **
   ** modalImage
   **
   ****/
  modalImage() {
    const $target = $('.js-modalimage')

    $target.magnificPopup({
      type: 'image',
      removalDelay: 300,
      mainClass: 'mfp-fade',
      closeOnContentClick: true,
      fixedContentPos: false,
      showCloseBtn: false,
    })
  }
}

export default Common
