/****
 **
 ** Plugins
 **
 ****/

// jQuery
import './vendor/jquery-global.js'

// Swiper
import Swiper from 'swiper'

// Slick
//import 'slick-carousel';

// Scroll Magic
import ScrollMagic from 'scrollmagic'

// Common
import Common from './component/common.js'

// Modal
import Modal from './component/modal.js'

// Lineup
//import Lineup from "./component/lineup";

//var hello = new Message('test!');
//hello.hello_world();

/****
 **
 ** サイト共通処理
 **
 ****/
;(function () {
  var common = new Common()
})()

/****
 **
 ** Modal
 **
 ****/
;(function () {
  var modal = new Modal()
})()

/****
 **
 ** Opening
 **
 ****/
;(function () {
  const target = $('.c-opening')
  const firstview = $('.p-home')

  $(window).on('load', function () {
    target.addClass('c-opening--active')

    // CSSに合わせる
    setTimeout(function () {
      target.addClass('c-opening--hidden')
    }, 2250)

    // CSSに合わせる
    setTimeout(function () {
      firstview.addClass('p-home--active')
    }, 3250)

    setTimeout(function () {
      firstview.addClass('p-home--clonehidden')
    }, 3000)

    setTimeout(function () {
      firstview.addClass('p-home--animationend')
    }, 3850)
  })
})()

/****
 **
 ** Firstview logo
 **
 ****/
;(function () {
  var controller = new ScrollMagic.Controller()

  $('#fixedlogo').before('<div id="fixedlogo_trigger"></div>')

  // Fixed Logo
  var scene1 = new ScrollMagic.Scene({
    triggerElement: '#service',
    triggerHook: 0.25,
  })
    // trigger a velocity opaticy animation
    .setClassToggle('#fixedlogo', 'c-logo--visible')
    //.addIndicators() // add indicators (requires plugin)
    .addTo(controller)

  var scene1_2 = new ScrollMagic.Scene({
    triggerElement: '#fixedlogo_trigger',
    triggerHook: 0.75,
  })
    // trigger a velocity opaticy animation
    .setClassToggle('#fixedlogo', 'c-logo--hidden')
    //.addIndicators() // add indicators (requires plugin)
    .addTo(controller)

  // Page top
  var scene2 = new ScrollMagic.Scene({
    triggerElement: '#service',
    triggerHook: 0.75,
  })
    // trigger a velocity opaticy animation
    .setClassToggle('.c-pagetop', 'c-pagetop--visible')
    //.addIndicators() // add indicators (requires plugin)
    .addTo(controller)

  // Scroll icon
  var scene3 = new ScrollMagic.Scene({
    triggerElement: '#service',
    triggerHook: 0,
  })
    // trigger a velocity opaticy animation
    .setClassToggle('.c-scroll', 'c-scroll--hidden')
    //.addIndicators() // add indicators (requires plugin)
    .addTo(controller)

  // Scroll icon
  var scene4 = new ScrollMagic.Scene({
    triggerElement: '#service',
    triggerHook: 0.015,
  })
    // trigger a velocity opaticy animation
    .setClassToggle('html', 'is-notfirstview')
    //.addIndicators() // add indicators (requires plugin)
    .addTo(controller)
})()

/****
 **
 ** Section animation
 **
 ****/
;(function () {
  var target_class = 'js-animation'
  var controller = new ScrollMagic.Controller()

  for (var i = 0; i < $('.' + target_class).length; i++) {
    $('.' + target_class)
      .eq(i)
      .addClass(target_class + '--' + i)

    start(target_class + '--' + i)
  }

  function start(classname) {
    var scene = new ScrollMagic.Scene({
      triggerElement: '.' + classname,
      triggerHook: 0.6,
      reverse: false,
    })
      // trigger a velocity opaticy animation
      .setClassToggle('.' + classname, 'js-animation--active')
      //.addIndicators() // add indicators (requires plugin)
      .addTo(controller)

    scene.on('enter', function () {
      setTimeout(function () {
        $('.' + classname).addClass('js-animation--end')
      }, 2000)
    })
  }
})()

/****
 **
 ** NEWS Slider
 **
 ****/
;(function () {
  const news__wrapper = $('.p-news__wrapper')
  const news_len = $('.p-news__item').length / 2

  if (news_len > 1) {
    const w = 100 * (news_len + 2) + '%'
    news__wrapper.css('width', w)

    const news_slide = new Swiper('.p-news', {
      wrapperClass: 'p-news__wrapper',
      slideClass: 'p-news__item',
      navigation: {
        nextEl: '.p-news__arrow--next',
        prevEl: '.p-news__arrow--prev',
      },
      loop: true,
    })
  } else {
    $('.p-news__arrow').css('opacity', '0')
  }
})()

/****
 **
 ** Lineup
 **
 ****/
/*(function() {
  var data = (function() {
    var return_value;
    var req = new XMLHttpRequest();
    req.onreadystatechange = function() {
      if(req.readyState == 4 && req.status == 200){
        return_value = JSON.parse(req.responseText);
      }
    };
    req.open("GET", '/json/lineup.json', false);
    req.send(null);

    return return_value;
  }());

  var lineup = new Lineup({
    card_target: '.js-lineup',
    tag: false,
    year: false,
    pager: false,
    display_max: 12,
    data: data
  });

  //var modal = new Modal();
}());*/

/****
 **
 ** Consultation text hover
 **
 ****/
;(function () {
  const wrapper = $('.p-consultation__faq')
  const target = $('.p-consultation__item')
  var index = 0

  target.hover(
    function () {
      index = $('.p-consultation__item').index(this)

      wrapper.addClass('p-consultation__faq' + '--' + index)
    },
    function () {
      wrapper.removeClass('p-consultation__faq' + '--' + index)
    },
  )
})()

/****
 **
 ** Strength Slider
 **
 ****/
;(function () {
  const wrapper = $('.p-strength__inner')
  const tab = $('.p-strength__unit')
  const item = $('.p-strength__item')

  var index = 0
  var max = tab.length - 1

  $('.p-strength__arrow--prev').addClass('p-strength__arrow--disabled')

  tab.on('click', function () {
    var before = index

    index = $('.p-strength__unit').index(this)

    if (index > before) {
      change('next')
    } else if (index < before) {
      change('before')
    }
  })

  $('.p-strength__arrow--prev').on('click', function () {
    if (index === 0) {
      return
      index = max
    } else {
      index = index - 1
    }

    change('prev')
  })

  $('.p-strength__arrow--next').on('click', function () {
    if (index === max) {
      return
      index = 0
    } else {
      index = index + 1
    }

    change('next')
  })

  function change(dir) {
    tab.removeClass('is-active')
    item.removeClass('is-active').removeClass('is-active-next').removeClass('is-active-prev')

    tab.eq(index).addClass('is-active')

    if (dir === 'next') {
      item.eq(index).addClass('is-active').addClass('is-active-next')
    } else {
      item.eq(index).addClass('is-active').addClass('is-active-prev')
    }

    wrapper.removeClass('p-strength__inner--0').removeClass('p-strength__inner--1').removeClass('p-strength__inner--2')

    if (index === 0) {
      wrapper.addClass('p-strength__inner--0')
    } else if (index === 1) {
      wrapper.addClass('p-strength__inner--1')
    } else {
      wrapper.addClass('p-strength__inner--2')
    }

    if (index === 0) {
      $('.p-strength__arrow--prev').addClass('p-strength__arrow--disabled')
    } else {
      $('.p-strength__arrow--prev').removeClass('p-strength__arrow--disabled')
    }

    if (index === max) {
      $('.p-strength__arrow--next').addClass('p-strength__arrow--disabled')
    } else {
      $('.p-strength__arrow--next').removeClass('p-strength__arrow--disabled')
    }
  }
})()

/****
 **
 ** Feature Tab
 **
 ****/
;(function () {
  const tab = $('.p-feature__item')
  const content = $('.p-feature__detail')

  tab.on('click', function () {
    var index = $('.p-feature__item').index(this)

    tab.removeClass('is-active')
    content.removeClass('is-active')

    tab.eq(index).addClass('is-active')
    content.eq(index).addClass('is-active')
  })
})()

/****
 **
 ** Value Tab
 **
 ****/
;(function () {
  // 高さ調整
  const list = $('.p-value__unit')
  var max = 0

  list.each(function () {
    var len = $(this).find('.p-value__listitem').length

    if (max < len) {
      max = len
    }
  })

  list.each(function () {
    var len = $(this).find('.p-value__listitem').length

    if (max > len && max % 2 == 1) {
      for (var i = 0; i < max - len; i++) {
        $(this).find('.p-value__listmenu').append('<li class="p-value__listitem p-value__listitem--empty">&nbsp;</li>')
      }
    }
  })

  // tab切り替え
  const tab_class = 'p-value__item'
  const tab = $('.' + tab_class)
  const target_class = 'p-value__feature'
  const target = $('.' + target_class)

  tab.on('click', function () {
    var index = $('.' + tab_class).index(this)

    target
      .removeClass(target_class + '--0')
      .removeClass(target_class + '--1')
      .removeClass(target_class + '--2')

    target.addClass(target_class + '--' + index)
  })
})()
