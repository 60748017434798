class Modal {
  constructor() {
    this.modal = $('.p-modal')
    this.form_template =
      '<form class="p-form">' +
      '<input id="article_title" type="hidden" value="{TITLE}" name="title">' +
      '<input type="hidden" name="category" value="応募">' +
      '<div class="p-form__unit"><span class="p-form__row">' +
      '<label class="c-label">お名前<sup>※</sup></label>' +
      '<input class="c-input p-form__required" name="name" type="text"><p class="p-form__error">※必須項目です。</p></span><span class="p-form__row">' +
      '<label class="c-label">会社名<sup>※</sup></label>' +
      '<input class="c-input p-form__required" name="company" type="text"><p class="p-form__error">※必須項目です。</p></span><span class="p-form__row">' +
      '<label class="c-label">部署名</label>' +
      '<input class="c-input" name="busho" type="text"></span><span class="p-form__row">' +
      '<label class="c-label">メールアドレス<sup>※</sup></label>' +
      '<input class="c-input p-form__required" name="mail" type="text"><p class="p-form__error">※必須項目です。</p></span><span class="p-form__row">' +
      '<label class="c-label">電話番号<sup>※</sup></label>' +
      '<input class="c-input p-form__required" name="tel" type="text"><p class="p-form__error">※必須項目です。</p></span></div>' +
      '<div class="p-form__unit"><span class="p-form__row">' +
      '<label class="c-label">お問い合わせ内容<sup>※</sup></label>' +
      '<textarea class="c-textarea p-form__required" name="content"></textarea><p class="p-form__error">※必須項目です。</p></span></div>' +
      '<p class="p-form__read">当社の「<a href="https://www.rio.ne.jp/privacy/" target="blank">プライバシーポリシー</a>」 を必ずご確認の上、お申込みください。</p>' +
      '<div class="c-button js-confirm">送信内容の確認</div>' +
      '</form>'

    this.article_data = (function () {
      var return_value
      var req = new XMLHttpRequest()
      req.onreadystatechange = function () {
        if (req.readyState == 4 && req.status == 200) {
          return_value = JSON.parse(req.responseText)
        }
      }
      req.open('GET', '/json/articles.json', false)
      req.send(null)

      return return_value
    })()

    this.initForm()
    this.initArticle()

    var self = this

    // Open
    $('body').on('click', '.js-confirm', function () {
      self.generateForm($(this))
    })
    $('body').on('click', '.js-modal', function () {
      self.generateArticle($(this))
    })

    // Close
    $('body').on('click', '.js-close', function () {
      self.close()
    })
  }
  initForm() {
    this.form_data = {
      name: '',
      company: '',
      busho: '',
      mail: '',
      tel: '',
      content: '',
      content_text: '',
      category: '',
    }
  }
  initArticle() {
    this.article_config = {
      categorys: {
        project: {
          jp: 'プロジェクト',
          en: 'Project',
        },
      },
      sub_categorys: {
        webinar: 'オンラインセミナー',
      },
    }

    //var html =
  }
  generateForm(self) {
    var $form = $(self).parent('form')
    var error_len = 0

    $form.find('.p-form__required').each(function () {
      if ($(this).val() === '') {
        error_len = error_len + 1
        $(this).next('.p-form__error').addClass('p-form__error--active')
      } else {
        $(this).next('.p-form__error').removeClass('p-form__error--active')
      }
    })

    if (error_len < 1) {
      this.form_data.name = $form.find('.c-input[name="name"]').val()
      this.form_data.company = $form.find('.c-input[name="company"]').val()
      this.form_data.busho = $form.find('.c-input[name="busho"]').val()
      this.form_data.mail = $form.find('.c-input[name="mail"]').val()
      this.form_data.tel = $form.find('.c-input[name="tel"]').val()
      var content = ''
      $form.find('input[name="content"]:checked').each(function (index, val) {
        if (index !== 0) {
          content += '、'
        }
        content += $(this).val()
      })
      this.form_data.content = content
      this.form_data.content_text = $form.find('.c-textarea[name="content_text"]').val()
      this.form_data.title = $form.find('input[name="title"]').val()
      this.form_data.category = $form.find('input[name="category"]').val()

      this.openComplate()
    }
  }
  generateArticle(self) {
    const id = $(self).attr('data-target')
    const data = this.article_data.articles[id]

    if (self.hasClass('js-modal--form')) {
      this.generateArticleForm(self)

      return
    }

    const content = (function () {
      const htmls = []

      for (var i = 0; i < data.contents.length; i++) {
        var blocks = []

        for (var j = 0; j < data.contents[i].modules.length; j++) {
          switch (data.contents[i].modules[j].type) {
            case 'text':
              blocks.push(
                `<div class="p-article__unit"><p class="p-article__text">${data.contents[i].modules[j].content}</p></div>`,
              )
              break

            case 'image':
              if (typeof data.contents[i].modules[j].content === 'object') {
                var images = (function () {
                  var html = []
                  for (var k = 0; k < data.contents[i].modules[j].content.length; k++) {
                    html.push(
                      `<figure class="p-article__image"><img src="${data.contents[i].modules[j].content[k]}" alt=""></figure>`,
                    )
                  }

                  return html.join('')
                })()

                blocks.push(`<div class="p-article__unit"><div class="p-article__imagelist">${images}</div></div>`)
              } else {
                blocks.push(
                  `<div class="p-article__unit"><figure class="p-article__image"><img src="${data.contents[i].modules[j].content}" alt=""></figure></div>`,
                )
              }

              break

            case 'link':
              var _blank = data.contents[i].modules[j].content.is_newwindow ? '_blank' : ''
              blocks.push(
                `<div class="p-article__unit"><a href="${data.contents[i].modules[j].content.href}" class="p-article__link" target="${_blank}">${data.contents[i].modules[j].content.text}</a></div>`,
              )

              break

            case 'list':
              var lists = (function () {
                var html = []

                html.push('<div class="p-article__unit">')

                if (typeof data.contents[i].modules[j].content.read !== '') {
                  html.push(`<p class="p-article__text">${data.contents[i].modules[j].content.read}</p>`)
                }

                html.push('<ul class="p-article__list">')

                for (var k = 0; k < data.contents[i].modules[j].content.list.length; k++) {
                  html.push(`<li class="p-article__listitem">${data.contents[i].modules[j].content.list[k]}</li>`)
                }

                html.push('</ul></div>')

                return html.join('')
              })()

              blocks.push(lists)

              break

            case 'order':
              var lists = (function () {
                var html = []

                for (var k = 0; k < data.contents[i].modules[j].content.length; k++) {
                  html.push(`<li class="p-article__orderitem">${data.contents[i].modules[j].content[k]}</li>`)
                }

                return html.join('')
              })()

              blocks.push(`<div class="p-article__unit"><ol class="p-article__order">${lists}</ol></div>`)

              break

            case 'note':
              var lists = (function () {
                var html = []

                for (var k = 0; k < data.contents[i].modules[j].content.length; k++) {
                  html.push(
                    `<li class="p-article__noteitem"><span>※</span><span>${data.contents[i].modules[j].content[k]}</span></li>`,
                  )
                }

                return html.join('')
              })()

              blocks.push(`<div class="p-article__unit"><ul class="p-article__note">${lists}</ul></div>`)

              break
          }
        }

        htmls.push(
          `<div class="p-article__group">` +
            `<h4 class="c-label">${data.contents[i].heading}</h4>` +
            `${blocks.join('')}` +
            `</div>`,
        )
      }

      return htmls.join('')
    })()

    const summary = (function () {
      var htmls = []

      var history = (function () {
        var lists = []

        for (var i = 0; i < data.guests.history.length; i++) {
          lists.push('')

          if (data.guests.history[i].heading !== '') {
            lists.push(`<h5 class="p-article__title">${data.guests.history[i].heading}</h5>`)
          }

          lists.push(`<p class="p-article__text">${data.guests.history[i].content}</p>`)
        }

        return lists.join('')
      })()

      htmls.push(
        `<div class="p-article__group">` +
          `<h4 class="c-label">開催日時</h4>` +
          `<p class="p-article__head">${data.date}<small>${data.time}</small></p>` +
          `</div>` +
          `<div class="p-article__group">` +
          `<h4 class="c-label">登壇者</h4>` +
          `<p class="p-article__head">${data.guests.name.jp}<small>${data.guests.name.en}</small></p>` +
          `</div>` +
          `<div class="p-article__group"><h4 class="c-label">登壇者プロフィール</h4>` +
          `${history}` +
          `</div>`,
      )

      return htmls.join('')

      /*
          `<div class="p-article__group">`
        + `<h4 class="c-label">開催日時</h4>`
        + `<p class="p-article__head">${data.date}<small>${data.time}</small></p>`
        + `</div>`
        + `<div class="p-article__group">`
        + `<h4 class="c-label">登壇者</h4>`
        + `<p class="p-article__head">${data.guests[0]}</p>`
        + `</div>`;
        */
    })()

    var form = data.is_form ? `<div class="p-article__form">` : ''
    form = data.is_form
      ? form +
        `<h4 class="p-article__read p-article__read--form">お申し込みはこちらをご入力の上、送信してください。</h4>`
      : ''
    form = data.is_form ? form + `<p class="p-article__text"><span>※</span>は必須項目です。</p>` : ''
    form = data.is_form ? form + `${this.form_template}` : ''
    form = data.is_form ? form + `</div>` : ''

    const page =
      `<article class="p-article">` +
      `<h2 class="c-heading c-heading--white">${this.article_config.categorys[data.category].en}<small>${
        this.article_config.categorys[data.category].jp
      }</small></h2>` +
      `<h3 class="p-article__heading">` +
      `<small>${this.article_config.sub_categorys[data.sub_category]}</small>` +
      `${data.title}` +
      `</h3>` +
      `<div class="p-article__column">` +
      `<div class="p-article__summary">` +
      `${summary}` +
      `</div>` +
      `<div class="p-article__content">` +
      `${content}` +
      `</div>` +
      `</div>` +
      form +
      `</article>`

    this.openModal(page)

    this.modal.find('#article_title').val(data.title)
  }
  generateArticleForm(self) {
    const id = $(self).attr('data-target')
    const data = this.article_data.articles[id]

    const page =
      `<article class="p-article">` +
      `<h2 class="c-heading c-heading--white">${this.article_config.categorys[data.category].en}<small>${
        this.article_config.categorys[data.category].jp
      }</small></h2>` +
      `<h3 class="p-article__heading">` +
      `<small>${this.article_config.sub_categorys[data.sub_category]}</small>` +
      `${data.title}` +
      `</h3>` +
      `<div class="p-article__form">` +
      `<h4 class="p-article__read p-article__read--form">お申し込みはこちらをご入力の上、送信してください。</h4>` +
      `<p class="p-article__text"><span>※</span>は必須項目です。</p>` +
      `${this.form_template}` +
      `</div>` +
      `</article>`

    this.openModal(page)

    this.modal.find('#article_title').val(data.title)
  }
  openComplate() {
    const formdata = new FormData()

    for (const key in this.form_data) {
      formdata.append(key, this.form_data[key])
    }

    var request = new XMLHttpRequest()
    request.open('POST', '/mail.php')
    request.send(formdata)

    var complate_template =
      '<h2 class="c-heading">お問い合わせ</h2>' +
      '<div class="p-form p-form--complate">' +
      '<p class="p-form__text">お問い合わせいただき、ありがとうございます。<br>担当者よりご連絡させていただきます。</p>' +
      '<div class="c-button js-close">閉じる</div>'

    if (this.modal.hasClass('is-open')) {
      this.rewriteModal(complate_template)
    } else {
      this.openModal(complate_template)
    }

    if (this.form_data.category === '応募') {
      this.datasend('seminar_form/thanks')
    } else {
      this.datasend('contact_form/thanks')
    }
  }
  openModal(content) {
    this.modal.find('.p-modal__inner').html(content)

    $('body').addClass('is-fixed')

    this.modal.addClass('is-open').addClass('animate__animated').addClass('animate__fadeIn')
  }
  rewriteModal(content) {
    var self = this

    this.modal.removeClass('animate__fadeIn').addClass('animate__animated').addClass('animate__fadeOut')

    setTimeout(function () {
      self.modal.find('.p-modal__inner').html(content)
      self.modal.removeClass('animate__fadeOut').addClass('animate__fadeIn')
    }, 400)
  }
  close() {
    var self = this

    $('body').removeClass('is-fixed')

    this.modal.removeClass('animate__fadeIn').addClass('animate__animated').addClass('animate__fadeOut')

    setTimeout(function () {
      self.modal
        .removeClass('animate__animated')
        //.removeClass('animate__fadeIn')
        .removeClass('animate__fadeOut')
        .removeClass('is-open')
    }, 400)
  }
  datasend(dammyurl) {
    var path = window.location.pathname

    if (path.match(/index.html/)) {
      path = path.replace('index.html', '')
    }

    dataLayer.push({
      trackPageview: path + dammyurl + '/',
      event: 'sendContact',
    })

    //console.log(path + dammyurl + '/');
  }
}
export default Modal
